import React, { memo } from 'react';
import { fill_vertical_all_center, flex } from '~/modules/AppLayout/FlexGridCss';
import { css } from '@emotion/react';
import { Divider } from '@mui/material';
import range from 'lodash/range';
import useMedia from '~/hooks/useMedia';
import { TRADE_RANGE_ITEMS, useVolumeAmountStore, } from '~/modules/ranking-realtime/useVolumeAmountStore';
import { useConditionState, } from '~/modules/screener/containers/useConditionState';
import { StrongWeakToggle, QueryDatePicker, TurnoverSlider, DaysSelect, SelectAmountRange, BigThreeSelect, BuySellToggle, } from '~/modules/screener/components/ScreenerConfig/ConfigItems';
import { useSnapshot } from 'valtio';
const QuerySettings = memo(function QuerySettings(props) {
    const { state, acts } = useConditionState.useContainer();
    const volumeAmountState = useSnapshot(useVolumeAmountStore);
    const { isPc } = useMedia();
    const conditionsBoxCss = css `
    ${flex.v.default};
    width: 100%;
    height: 100%;
    gap: 8px;
  `;
    const detailsConditionBoxCss = css `
    ${flex.h.default};
    width: 100%;
  `;
    const DateElement = function () {
        return (<div css={css `
          ${flex.h.default};
          width: 50%;
        `}>
        <QueryDatePicker value={state.date} changeDateDelegate={(value) => {
                if (value) {
                    const date = value.format('YYYY-MM-DD');
                    if (date !== 'Invalid Date')
                        acts.setDate(value.format('YYYY-MM-DD'));
                }
            }} inputProps={{ style: { height: 35 } }}/>
      </div>);
    };
    const TypeElement = function () {
        return (<div css={css `
          ${flex.h.default};
          width: 50%;
          height: ${isPc ? '35px' : '35px'};
          & button {
            width: 100%;
          }
        `}>
        <StrongWeakToggle clickDelegate={e => 
            /** @ts-expect-error WHY：升上去nextjs@12, react@18 後壞了，暫不知何解 */
            acts.setPower(e.currentTarget.value)} value={state.power}/>
      </div>);
    };
    const Big3TypeElement = function () {
        return (<div css={css `
          ${flex.h.default};
          height: ${isPc ? '35px' : '35px'};
        `}>
        <BuySellToggle value={state.action} clickDelegate={e => {
                /** @ts-expect-error WHY：升上去nextjs@12, react@18 後壞了，暫不知何解 */
                acts.setAction(e.currentTarget.value);
            }}/>
      </div>);
    };
    const Big3Element = function () {
        return (<BigThreeSelect containerCss={css `
          ${flex.h.default};
          width: 50%;
          height: 35px;
        `} value={state.institution} changeDelegate={e => acts.setInstitution(e.target.value)}/>);
    };
    const TurnoverElement = function () {
        return (<div css={css `
          margin-left: 16px;
        `}>
        <TurnoverSlider value={state.threshold} changeDelegate={(_, value) => acts.setThreshold(value)}/>
      </div>);
    };
    const DaysElement = function () {
        return (<DaysSelect containerCss={css `
          ${flex.h.default};
          width: 50%;
          height: 35px;
        `} value={state.days} options={range(3, 20, 1)} changeDelegate={e => acts.setDays(e.target.value)}/>);
    };
    const SelectAmountRanged = function () {
        return (<SelectAmountRange containerCss={css `
          ${flex.h.default};
          width: 50%;
          height: 35px;
        `} options={TRADE_RANGE_ITEMS} value={volumeAmountState.doubleTradeValueIndex} changeDelegate={e => {
                const index = e.target.value;
                const rangeItem = volumeAmountState.TRADE_RANGE_ITEMS[index];
                useVolumeAmountStore.doubleTradeValueIndex = index;
                useVolumeAmountStore.amountGreaterThan = rangeItem.value[0];
                useVolumeAmountStore.amountLessThan = rangeItem.value[1];
            }}/>);
    };
    const startSelectedResource = state.resources.find(r => r.title === '趨勢轉折');
    acts.setCurrentResource(startSelectedResource);
    return (<div css={css `
        ${fill_vertical_all_center}
      `}>
      {/* 比較個股、模式選擇 */}
      <Divider css={css `
          margin: 1px;
        `} light/>
      <div css={conditionsBoxCss}>
        <div css={detailsConditionBoxCss}>
          <DateElement />
          {state.currentResource.resourceKey === 'z_score' && <TypeElement />}
          {state.currentResource.resourceKey === 'big3_consecutive' && <Big3TypeElement />}
          {state.currentResource.resourceKey === 'turnover_rate' && <TurnoverElement />}
        </div>
        <div css={detailsConditionBoxCss}>
          <DaysElement />
          {state.currentResource.resourceKey === 'big3_consecutive' && <Big3Element />}
          {state.currentResource.resourceKey !== 'big3_consecutive' && <SelectAmountRanged />}
        </div>
      </div>
    </div>);
});
export default QuerySettings;
